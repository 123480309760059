<template>
  <v-dialog
    :value="showModal"
    :content-class="dialogClass"
    :persistent="persistent"
    :width="width"
    :fullscreen="fullscreen"
    scrollable
    @keydown.esc="closeModal(cancelBtnFunc)">
    <v-card
      class="cy-modal"
      data-cy="modal">
      <v-card-title
        v-if="!hideToolbar"
        class="d-flex flex-column align-stretch pa-0">
        <div :class="`cy-modal__bar ${headerColorClass}`"/>
        <div
          :class="['cy-modal__header', {
            'px-8': !fullscreen,
            'px-0': fullscreen,
          }]">
          <slot name="modal-upper-title"/>
          <h1 class="h4 cy-modal__title">
            {{ headerTitle }}
          </h1>
          <div>
            <slot name="modal-icon">
              <v-icon
                v-if="headerIcon"
                large>
                {{ headerIcon }}
              </v-icon>
            </slot>
          </div>
        </div>
      </v-card-title>

      <v-card-text
        data-cy="modal-content"
        :class="['cy-modal__content py-0', {
          'px-8': !fullscreen,
          'px-0': fullscreen,
        }]">
        <slot name="default"/>
      </v-card-text>

      <v-card-actions
        v-if="!hideActions"
        :class="['pt-6 pb-6 d-flex justify-end', {
          'px-8': !fullscreen,
        }]">
        <slot name="modal-buttons">
          <CyButton
            v-show="!cancelBtnHidden"
            :disabled="cancelBtnDisabled"
            icon="close"
            theme="primary"
            variant="secondary"
            data-cy="cancel-button"
            @click="closeModal(cancelBtnFunc)">
            {{ cancelBtnText }}
          </CyButton>

          <CyButton
            v-show="!actionBtnHidden"
            class="ml-4"
            :disabled="actionBtnDisabled"
            :theme="actionBtnColor"
            :loading="loading"
            :icon="actionBtnIcon"
            data-cy="submit-button"
            @click="closeModal(actionBtnFunc)">
            {{ actionBtnText }}
          </CyButton>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export const themeColors = ['info', 'error', 'warning', 'success']
export const buttonsColors = ['secondary', 'error', 'warning', 'success']
export const modalTypes = ['create', 'update', 'delete', 'warning', 'info', 'success']

const VUETIFY_ANIMATION_TIME = 100

/**
 * Modal component
 *
 * Optional
 * --------
 * @param {String}    [modalType='info']          Define the type of modal
 *                                                  This effects:
 *                                                    - default modal header colour
 *                                                    - default action btn (text, icon and color)
 *                                                  ! must be 1 of: ['create', 'update', 'delete', 'warning', 'info']
 * @param {String}    [headerTitle='']            Modal header title
 * @param {String}    [headerIcon=undefined]      Modal header icon
 *                                                   ! must be a font-awesome or material icon class
 * @param {Boolean}   [loading=false]             Loading state of the action button
 * @param {Boolean}   [large=false]               Will increase the size of the modal
 * @param {Boolean}   [small=false]               Will decrease the size of the modal
 * @param {Boolean}   [fullscreen=false]          Fullscreen modal
 * @param {Number}    [width=700]                 Width of the modal, in px
 *                                                  Default values are:
 *                                                    - 1140 if large is set to true
 *                                                    - 554 if small is set to true
 *                                                    - 700 otherwise
 * @param {Boolean}   [dialogClass='']            Any custom class(es) to set on the modal itself
 * @param {Boolean}   [hideToolbar=false]         Hide toolbar
 * @param {Boolean}   [hideActions=false]         Hide actions (including the slot)
 * @param {Boolean}   [persistent=true]           Persistent
 *
 * ---action button
 * @param {String}    [actionBtnText=undefined]   Action button text
 * @param {Function}  [actionBtnFunc=Function]    Function called when action button is clicked
 * @param {String}    [actionBtnIcon='check']     Icon class for the action button
 *                                                  ! must be a font-awesome or material icon class
 * @param {String}    [actionBtnColor='success']  Action button color
 *                                                  ! must be 1 of: ['secondary', 'error', 'warning', 'success']
 * @param {Boolean}   [actionBtnDisabled=false]   Disabled logic for action button
 * @param {Boolean}   [actionBtnHidden=false]     Hide action button
 *
 * ---cancel button
 * @param {String}    [cancelBtnText=undefined]   Cancel button text
 * @param {Function}  [cancelBtnFunc=Function]    Function called when cancel button is clicked
 * @param {Boolean}   [cancelBtnDisabled=false]   Disabled logic for cancel button
 * @param {Boolean}   [cancelBtnHidden=false]     Hide cancel button
 *
 */
export default {
  name: 'CyModal',
  props: {
    modalType: {
      type: String,
      validator: (modalType) => modalTypes.includes(modalType),
      default: 'info',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerIcon: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default () {
        if (this.small) return 554
        if (this.large) return 1140
        if (this.fullscreen) return null
        return 700
      },
    },
    dialogClass: {
      type: String,
      default: '',
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    keepOpenOnActionClick: {
      type: Boolean,
      default: false,
    },
    actionBtnText: {
      type: String,
      default () {
        return {
          delete: this.$t('forms.btnDelete'),
          info: this.$t('forms.btnConfirm'),
          warning: this.$t('forms.btnConfirm'),
          success: this.$t('forms.btnConfirm'),
        }[this.modalType] || this.$t('forms.btnSave')
      },
    },
    actionBtnFunc: {
      type: Function,
      default: () => {},
    },
    actionBtnIcon: {
      type: String,
      default () {
        return {
          delete: 'fas fa-trash',
        }[this.modalType] || 'check'
      },
    },
    actionBtnColor: {
      type: String,
      validator: (actionBtnColor) => buttonsColors.includes(actionBtnColor),
      default () {
        return {
          delete: 'error',
          warning: 'warning',
          info: 'secondary',
          create: 'secondary',
          update: 'secondary',
        }[this.modalType] || 'success'
      },
    },
    actionBtnDisabled: {
      type: Boolean,
      default: false,
    },
    actionBtnHidden: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default () {
        return {
          info: this.$t('forms.btnClose'),
        }[this.modalType] || this.$t('forms.btnCancel')
      },
    },
    cancelBtnFunc: {
      type: Function,
      default: () => {},
    },
    cancelBtnDisabled: {
      type: Boolean,
      default: false,
    },
    cancelBtnHidden: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showModal: false,
  }),
  computed: {
    headerColorClass () {
      const headerColor = {
        delete: 'error',
        warning: 'warning',
        success: 'success',
      }[this.modalType] || 'info'
      return `cy-${headerColor}-bg`
    },
  },
  mounted () {
    this.showModal = true
  },
  methods: {
    closeModal (methodToCall) {
      if (!this.keepOpenOnActionClick) this.showModal = false
      setTimeout(() => methodToCall(), VUETIFY_ANIMATION_TIME)
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-modal {
  &__bar {
    height: 8px;
  }

  &__header {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 24px;
  }

  &__title {
    word-break: normal;
  }

  &__content ::v-deep {
    b,
    strong {
      white-space: pre;
    }
  }
}

.cy {
  &-info-bg { background: cy-get-gradient("info", "main", "right"); }
  &-error-bg { background: cy-get-gradient("error", "main", "right"); }
  &-warning-bg { background: cy-get-gradient("warning", "main", "right"); }
  &-success-bg { background: cy-get-gradient("success", "main", "right"); }
}
</style>
